import Header from "./components/header";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Client from "./pages/Client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Mailer from "./components/Mailer";
import BootstrapCarousel from "./components/BootstrapCarousel";
import BootstrapCard from "./components/BootstrapCard";



function App() {
  return (

    <div>
        <Header/>
        <BootstrapCarousel/>
        <BootstrapCard/>
        <Mailer/>
        


         <Routes>         
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="client" element={<Client />} />
        </Routes>
    </div>

  );
}

export default App;
