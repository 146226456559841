import React from 'react';
import { Card } from 'react-bootstrap';
import { CardGroup } from 'react-bootstrap';

function BootstrapCard() {

    return (
        <CardGroup>
            <Card>
                <Card.Img variant="top" src="/c1.jpg" />
                <Card.Body>
                    <Card.Title>Digital Web</Card.Title>
                    <Card.Text>
                    Business & Brand Consulting.Product and Consumer Research.Branding and Identity
            creation.Brand and Campaign Planning.Social Media.Public Relations.Influencer & Blog Marketing.
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">Strategy,Content & Design</small>
                </Card.Footer>
            </Card>
            <Card>
                <Card.Img variant="top" src="/c2.jpg" />
                <Card.Body>
                    <Card.Title>Brand Websites.Ecommerce Solutions.</Card.Title>
                    <Card.Text>
                    Performance Marketing.
            Search Engine Optimisation (SEO).Digital Command Centers
            Customer Experience Management.Data & Insights.Online Reputation Management.Logo &
            Merchandising.
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">Technology,Performance</small>
                </Card.Footer>
            </Card>
            <Card>
                <Card.Img variant="top" src="/c3.jpg" />
                <Card.Body>
                    <Card.Title>IOT</Card.Title>
                    <Card.Text>
                    Brand & Corporate Films
                    Photography.Customer Testimonials
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">Consumer Experience</small>
                </Card.Footer>
            </Card>
        </CardGroup>
    )

}

export default BootstrapCard;