import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import '../slide/slide.css';


function BootstrapCarousel() {
    return (
        <div>
             <Carousel className='main-slide'>
                <div>
                    <img src="sld1.jpg" height="300px" width="200px"/>
                   
                </div>
                <div>
                    <img src="sld2.jpg" height="300px" width="200px" />
                   
                </div>
                <div>
                    <img src="sld3.jpg"height="300px" width="200px" />
                    
                </div>
            </Carousel>
        </div>
    )
}

export default BootstrapCarousel;
