function Home(){

    return(

        
        <div className="alert alert-secondary" role="alert">
       
        <p>
          Mahabhairab Paruwa Road, Mahabhairab, Tezpur, Assam 784001
        </p>
        <p>
          + 91 70029-22525
        </p>
        <p className="text-center text-md-left">©2022 Copyright:
          <a href="/okid1.ico">
            <strong>ORKID SOFTWARE SERVICES</strong>
          </a>
        </p>
       </div>
    );
}

export default Home;