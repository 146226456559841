import emailjs from "emailjs-com";
import React from 'react';
import {className} from "react-router-dom";
const Mailer = () => {

    function SendEmail(e)
    {
      e.preventDefault();

      emailjs.sendForm(
        'service_9k8j1nh',
        'template_83j32qe',
        e.target,
        '2JIUhRBno12hAY0CA'
    ).then(res =>{
      {alert("Message Sent Succesfully")};
    }).catch(err =>console.log(err));

  }

  return (
    <div classname="container border"
    style={{margintop:"50px",
    width:"100%",
    backgroundImage:"url(https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg?size=626&ext=jpg)",
    backgroundPosition:"center",
    backgroundSize:"cover"
  }}>
      <h1 style={{margintop:"50px"}}>Contact Form</h1>
      <form className="row" style={{margin:"5px 20px 20px 20px"}} onSubmit={SendEmail}>
        <label>Name</label>
        <input type="text" name="name" className="form-control"/>

        <label>E-mail</label>
        <input type="email" name="user_email" className="form-control"/>

        <label>Phone</label>
        <input type="text" name="phone" className="form-control"/>

        <label>Description</label>
        <textarea name="message" rows="4" className="form-control" />

        <input type="submit" value="Send" className="form-control btn btn-primary"
                  style={{margintop:"30px"}}
        />

      </form>

    </div>
  )
}

export default Mailer;
